<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left booking">
          <div class="kw-back">
            <h3 class="mb-0">{{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="../../../../../public/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">

        </div>
      </div>

<!--      <pending-booking-list-table-->
<!--        @onViewBooking="openBookingViewModal"-->
<!--        @onEditBooking="openBookingEditModal"-->
<!--        @onDeleteBooking="deleteBooking"-->
<!--        :key="renderKey * 100"-->
<!--      />-->

      <div
        v-if="isConfirmBookingModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isConfirmBookingModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'BOOKING'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("BOOKINGS.CONFIRM_BOOKING") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeBookingModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <confirm-booking-component
                v-if="openBooking"
                :bookingId="openBooking.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ConfirmBookingComponent from "./components/ConfirmBookingComponent";

const QUERY_ACTIONS_CONFIRM = 'confirm';

export default {
  layout: "DashboardLayout",

  components: {
    ConfirmBookingComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const bookingId = this.$route.query.id;
    const action = this.$route.query.action;
    let isConfirmBookingModalOpened = false;
    let openBooking = null;

    if (bookingId && action === QUERY_ACTIONS_CONFIRM) {
      isConfirmBookingModalOpened = true;
      openBooking = { id: bookingId };
    }

    return {
      isConfirmBookingModalOpened: isConfirmBookingModalOpened,
      openBooking: openBooking,
      renderKey: 1,
    };
  },

  methods: {
    openBookingConfirmModal() {
      this.closeBookingModal();
      this.isConfirmBookingModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "Pending Bookings",
          query: { action: QUERY_ACTIONS_CONFIRM },
        }).href
      );
    },
    closeBookingModal() {
      this.isConfirmBookingModalOpened = false;
      this.openBooking = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Bookings",
          query: {},
        }).href
      );
    },

    async deleteBooking(booking) {
      const confirmation = await swal.fire({
        title: this.$t("BOOKINGS.DELETE_THIS_BOOKING"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("bookings/destroy", booking.id);
          this.renderKey++;
          this.closeBookingModal();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
