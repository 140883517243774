<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!booking">
      <span class="loader"></span>
    </span>

    <el-row :gutter="10">
      <el-col :span="14">
        <h1>Confirmer la réservation</h1>
        <small>référence #{{ booking.code }}</small>

        <el-tabs v-model="activeTab">
          <el-tab-pane label="Réserver avec garantie" name="with_payment">

          </el-tab-pane>
          <el-tab-pane label="Réserver sans garantie" name="without_payment">
            <booking-confirmation-without-payment
                v-if="booking"
                :bookingData="booking"
                @bookingSubmitted="handleSubmit"
            />
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="10">
        <div class="summary">
          <h2>Résumé final de la réservation</h2>
          <div class="section">
            <dl class="row">
              <dt>Date de réservation</dt>
              <dd>
                {{ booking.pricing.duration }} nuits: {{ $formatDate(booking.start_at, 'D MMM') }} au {{ $formatDate(booking.end_at, 'Do MMM') }}
              </dd>
            </dl>
          </div>
          <div class="section">
            <h4>{{ booking.spot.name }}</h4>
            <dl class="row">
              <dt>Roulotte num (4 x jours)</dt>
              <dd>
                80 CAD
              </dd>
            </dl>
            <dl class="row">
              <dt>{{ $t('COMMON.SUBTOTAL') }}</dt>
              <dd>
                670 CAD
              </dd>
            </dl>
          </div>
          <div class="section">
            <dl class="row">
              <dt>TPS</dt>
              <dd>
                5.08 CAD
              </dd>
            </dl>
            <dl class="row">
              <dt>TVQ</dt>
              <dd>
                12,14 CAD
              </dd>
            </dl>
          </div>
          <div class="total">
            <dl class="row">
              <dt>
                <b>Total</b>
                <small>inclus 2.24 CAD de taxes</small>
              </dt>
              <dd>
                5.08 CAD
              </dd>
            </dl>
            <dl class="row">
              <dt>TVQ</dt>
              <dd>
                12,14 CAD
              </dd>
            </dl>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {cloneDeep} from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import BookingConfirmationWithoutPayment from "../partials/form/BookingConfirmationWithoutPayment";
import {Col, Row, Tabs, TabPane} from "element-ui";
import defaultBooking from "../defaultBooking";

export default {
  layout: "DashboardLayout",

  components: {
    BookingConfirmationWithoutPayment,
    [Col.name]: Col,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },

  mixins: [alertLeave],

  props: {
    bookingId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      booking: defaultBooking,
      activeTab: 0,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("bookings/get", this.bookingId);
        this.booking = this.$store.getters["bookings/booking"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(booking) {
      this.loading = true;
      const bookingData = cloneDeep(booking);

      try {
        await this.$store.dispatch("bookings/update", bookingData);
        this.$notify({
          type: "success",
          message: this.$t("BOOKINGS.BOOKING_UPDATED"),
        });
        const booking = await this.$store.getters["bookings/booking"];
        this.$emit("onViewBooking", booking, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
