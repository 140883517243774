<template>
  <div class="confirmation-without-payment">

        <!-- confirmation_expires_at -->
        <div class="form-wrapper full">
          <base-input :label="$t('BOOKINGS.SELECT_CONFIRMATION_EXPIRATION_DATE')">
            <el-date-picker
                v-model="booking.confirmation_expires_at"
                type="date"
                format="dd MMM yyyy"
                value-format="yyyy-MM-dd"
                :clearable="false"
            />
          </base-input>
        </div>

        <!-- comment -->
        <div class="form-wrapper full">
          <base-input :label="$t('BOOKINGS.INTERNAL_NOTE')">
            <el-input type="textarea" v-model="booking.comment"></el-input>
          </base-input>
          <validation-error :errors="apiValidationErrors.comment" />
        </div>

        <hr>

        <!-- email -->
        <div class="form-wrapper full">
          <base-input :label="$t('BOOKINGS.SEND_TO_CUSTOMER_EMAIL')">
            <el-input type="email" v-model="booking.customer.email"></el-input>
          </base-input>
        </div>

        <div class="buttons-group">
          <el-button class="continue" type="primary" @click="handleSubmit">
            {{ $t("COMMON.CONTINUE") }}
          </el-button>
        </div>

  </div>

</template>
<script>
import {cloneDeep} from "lodash";
import {Button, Col, DatePicker, Divider, Header, Image, Row, Step, Steps, Select, Option} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError";
import defaultBooking from "../../defaultBooking";

export default {
  name: "booking-confirmation-without-payment",

  components: {
    ValidationError,
    [Header.name]: Header,
    [Divider.name]: Divider,
    [Row.name]: Row,
    [Col.name]: Col,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Image.name]: Image,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    bookingData: {
      type: Object,
      default: defaultBooking,
      description: "Booking object",
    },
  },

  data() {
    return {
      booking: this.bookingData,
      showModal: false,
      showBookingCheckModal: false,
      loading: false,
      formErrors: null,
    };
  },

  created() {},

  mounted() {},

  computed: {},

  methods: {
    async handleSubmit() {
      await this.sendInformation();
    },

    async sendInformation() {
      this.loading = true;

      try {
        await this.$store.dispatch("bookings/update", this.booking);
        this.$emit("bookingConfirmed", this.booking);
      } catch (error) {
        this.formErrors = error.response.data.errors;
      }
      this.loading = false;
    },

    onFormChanged() {
      this.$emit("onFormChanged", this.booking);
    },

    async toggleModalState() {
      this.showModal = !this.showModal;
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    bookingData: {
      handler: function (bookingData) {
        if (bookingData) {
          this.booking = {
            ...this.booking,
            ...cloneDeep(bookingData),
          };
        }
      },
      deep: true,
    },
  },
};
</script>
